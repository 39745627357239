import { Typography } from '@mui/material';
import React from 'react';

import DynamicField from './dynamic-field';
import { FieldItem, FormSection as FormSectionType } from './types';

export default function FormSection({
    sectionProps,
    formState,
    register,
    control,
    errors,
    setValue,
}: {
    register: any;
    formState: any;
    errors: any;
    setValue: any;
    control: any;
    sectionProps: FormSectionType;
}) {
    const { name, items } = sectionProps;
    console.log('Form section:', name);
    return (
        <>
            <Typography className="form-section" variant="h3" gutterBottom>
                {name}
            </Typography>
            {items.map((item: FieldItem) => {
                const parent = item.parent ? '-parent' : '';
                return (
                    <DynamicField
                        key={item.name + parent}
                        formState={formState}
                        fieldProps={item}
                        register={register}
                        control={control}
                        errors={errors}
                        setValue={setValue}
                    />
                );
            })}
        </>
    );
}
