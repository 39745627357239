import { Box, FormHelperText, Theme, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';

import Button from '../button';
import FormSection from './form-section';
import { FormData, FormSection as FormSectionType } from './types';

// function createDownloadLink(owner: string, repo: string): string {
//     return `https://github.com/${owner}/${repo}/archive/master.zip`;
// }

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.fitech.contrastText,
            backgroundColor: theme.palette.fitech.main,
            transition: theme.transitions.create(['color', 'background-color']),
            '&:hover': {
                color: theme.palette.common.black,
            },
        },
    }),
);
*/

interface PropTypes {
    assignmentUuid: string;
    formData: FormData;
    onSubmit: () => void;
    downloadCode?: () => void;
}

export default function Review(props: PropTypes) {
    console.log('THIS IS WORKING?', props);

    // https://react-hook-form.com/api/#register
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: { errors },
    } = useForm();

    // const formState = watch();
    return (
        <>
            {props.formData ? (
                <Box
                    component="form"
                    id="review-form"
                    mx={1}
                    my={2}
                    onSubmit={handleSubmit(props.onSubmit)}
                >
                    {props.downloadCode && (
                        <Box my={3}>
                            {/* move  download out of form component? */}
                            <Typography id="download-project" variant="h3">
                                Download submission
                            </Typography>
                            <Typography
                                id="download-project-description"
                                color="textSecondary"
                                gutterBottom
                            >
                                Click the button below to download a zip-file containing the code
                                for this review.
                            </Typography>

                            <Box sx={{ mt: 2, mb: 5 }}>
                                <Button
                                    kind="fitech"
                                    sx={{
                                        color: 'fitech.contrastText',
                                        backgroundColor: 'fitech.main',
                                        transition: (theme) =>
                                            theme.transitions.create(['color', 'background-color']),
                                        '&:hover': {
                                            color: 'common.black',
                                        },
                                    }}
                                    color="inherit"
                                    onClick={props.downloadCode}
                                >
                                    Download project
                                </Button>
                            </Box>
                        </Box>
                    )}
                    {props.formData?.sections?.map((section: FormSectionType) => (
                        <FormSection
                            key={section.name}
                            formState={undefined}
                            sectionProps={section}
                            register={register}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                        />
                    ))}
                    <Box sx={{ mt: 2, mb: 5 }}>
                        <Button
                            type="submit"
                            kind="fitech"
                            sx={{
                                color: 'fitech.contrastText',
                                backgroundColor: 'fitech.main',
                                transition: (theme) =>
                                    theme.transitions.create(['color', 'background-color']),
                                '&:hover': {
                                    color: 'common.black',
                                },
                            }}
                            color="inherit"
                        >
                            Submit review
                        </Button>
                    </Box>
                    <FormHelperText>
                        {Object.keys(errors).length > 0 && 'Some fields still require attention.'}
                    </FormHelperText>
                </Box>
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
}
