import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { Remove } from '@mui/icons-material';
import { Trans } from 'react-i18next';
import React, { useContext, useEffect } from 'react';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import Button from '../button';
import { FieldItem, FieldType, FormSection as FormSectionType } from './types';

/*
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        button: {
            color: theme.palette.fitech.contrastText,
            backgroundColor: theme.palette.fitech.main,
            transition: theme.transitions.create(['color', 'background-color']),
            '&:hover': {
                color: theme.palette.common.black,
            },
        },
    }),
);
*/

export default function FormResult(props) {
    const [showDetails, setShowDetails] = React.useState(false);
    const { state: authState } = useContext(AuthContext);

    useEffect(() => {
        console.log('REVIEW RESULT', props.reviewId);

        if (props.assignmentUuid) {
            void postAnalyticsData(authState.token, 'peer-review', {
                assignment_uuid: props.assignmentUuid,
                review_id: props.reviewId,
                action: 'view',
            });
        }
    }, [props.reviewId]);

    if (props.formData && props.formState) {
        return (
            <>
                {/* Show textfields  */}
                {props.formData.sections
                    .filter((section: FormSectionType) => section.name === 'Open feedback')
                    .map((section: FormSectionType) => {
                        const { name, items } = section;
                        console.log('Form section:', name);
                        return (
                            <React.Fragment key={name}>
                                <Typography className="form-section" variant="h3" gutterBottom>
                                    {name}
                                </Typography>
                                {items.map((item: FieldItem) => (
                                    <ImmutableField
                                        key={item.name}
                                        fieldProps={item}
                                        formState={props.formState}
                                        isAdmin={props.isAdmin}
                                    />
                                ))}
                            </React.Fragment>
                        );
                    })}

                <Button
                    sx={{ mb: 2 }}
                    onClick={() => setShowDetails((prevState) => !prevState)}
                    kind="fitech"
                    color="inherit"
                >
                    {showDetails ? <Trans i18nKey="showLess" /> : <Trans i18nKey="showMore" />}
                </Button>

                {/* Show checkboxes  */}
                {showDetails ? (
                    <>
                        {props.formData.sections
                            .filter((section: FormSectionType) => section.name !== 'Open feedback')
                            .map((section: FormSectionType) => {
                                const { name, items } = section;
                                console.log('Form section:', name);
                                return (
                                    <React.Fragment key={name}>
                                        <Typography
                                            className="form-section"
                                            variant="h3"
                                            gutterBottom
                                        >
                                            {name}
                                        </Typography>
                                        {items.map((item: FieldItem) => (
                                            <ImmutableField
                                                key={item.name}
                                                fieldProps={item}
                                                formState={props.formState}
                                                isAdmin={props.isAdmin}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        <Button
                            sx={{ mb: 2 }}
                            onClick={() => setShowDetails(false)}
                            kind="fitech"
                            color="inherit"
                        >
                            <Trans i18nKey="showLess" />
                        </Button>
                    </>
                ) : (
                    <Divider light />
                )}
            </>
        );
    } else {
        return null;
    }
}

function ImmutableField({ fieldProps, formState, isAdmin = false }: { fieldProps: FieldItem; formState: any, isAdmin: boolean }) {
    const { name, content, question, description, legend, options, type, componentProps } =
        fieldProps || {};

    const { defaultValue, multiline, rows, fullWidth, min, max, step, required, inputProps } =
        componentProps || {};

    if (!isAdmin && name.includes('teacher')) {
        return null;
    }

    switch (type) {
        case FieldType.Description: {
            return (
                <Box mb={2}>
                    <Typography id={name} variant="body1" gutterBottom>
                        {content}
                    </Typography>
                </Box>
            );
        }
        case FieldType.TextField: {
            return (
                <Box mb={2}>
                    <Typography variant="body1" gutterBottom>
                        {question}
                    </Typography>
                    {description && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {description}
                        </Typography>
                    )}
                    <Typography sx={{ px: 2 }}>{formState[name] || '-'}</Typography>
                </Box>
            );
        }
        case FieldType.Checkbox: {
            return (
                <Box mb={2}>
                    {/* (NEED TO) VALIDATE THE CHECKED STATUS MANUALLY */}
                    <FormControl required={required} component="fieldset">
                        {legend && <FormLabel component="legend">{legend}</FormLabel>}
                        <FormGroup>
                            {options?.map((option, parentIndex) => {
                                const parent = option.children && option.children.length > 0;

                                return (
                                    <React.Fragment key={`${name}-parent-${parentIndex}`}>
                                        <FormControlLabel
                                            control={
                                                parent ? (
                                                    <Checkbox
                                                        name={`${name}-parent-${parentIndex}`}
                                                        color="primary"
                                                        checked={false}
                                                        disableFocusRipple
                                                        indeterminate={true}
                                                        disableRipple
                                                        inputProps={{
                                                            'aria-label': `${name}-parent-${parentIndex}-checkbox`,
                                                        }}
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        name={`${name}-parent-${parentIndex}`}
                                                        color="primary"
                                                        checked={
                                                            !!formState[
                                                                `${name}-parent-${parentIndex}`
                                                            ]
                                                        }
                                                        inputProps={{
                                                            'aria-label': `${name}-parent-${parentIndex}-checkbox`,
                                                        }}
                                                    />
                                                )
                                            }
                                            label={option.label}
                                        />
                                        {option.description && (
                                            <Typography
                                                sx={{ py: 1, px: 3 }}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {option.description}
                                            </Typography>
                                        )}
                                        {option.children?.map((child, childIndex) => (
                                            <Box
                                                pl={2}
                                                key={`${name}-parent-${parentIndex}-child-${childIndex}`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={`${name}-parent-${parentIndex}-child-${childIndex}`}
                                                            color="primary"
                                                            size="small"
                                                            checked={
                                                                !!formState[
                                                                    `${name}-parent-${parentIndex}-child-${childIndex}`
                                                                ]
                                                            }
                                                            inputProps={{
                                                                'aria-label': `${name}-parent-${parentIndex}-child-${childIndex}-checkbox`,
                                                            }}
                                                        />
                                                    }
                                                    label={child.label}
                                                />
                                                {/* eslint-disable indent */}
                                                {child.description
                                                    ? Array.isArray(child.description) && (
                                                          <List dense disablePadding>
                                                              {child.description.map(
                                                                  (item, index) => (
                                                                      <ListItem
                                                                          key={`${item}-${index}`}
                                                                          sx={{ px: 2 }}
                                                                          disableGutters
                                                                      >
                                                                          <ListItemIcon>
                                                                              <Remove />
                                                                          </ListItemIcon>
                                                                          <ListItemText
                                                                              primary={item}
                                                                          />
                                                                      </ListItem>
                                                                  ),
                                                              )}
                                                          </List>
                                                      )
                                                    : typeof child.description === 'string' && (
                                                          <Typography
                                                              sx={{ px: 2 }}
                                                              variant="body2"
                                                              color="textSecondary"
                                                          >
                                                              {child.description}
                                                          </Typography>
                                                      )}
                                                {/* eslint-enable indent */}
                                            </Box>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
            );
        }
        case FieldType.Divider: {
            return (
                <Box mb={2}>
                    <Divider />
                </Box>
            );
        }
        default:
            return (
                <Box my={2}>
                    <Typography id="unknown-field-type" color="error">
                        Unknown field type. Please specify a valid field type.
                    </Typography>
                </Box>
            );
    }
}
