import { Box, Button } from '@mui/material';
import { Trans } from 'react-i18next';
import React, { useContext } from 'react';
import { SubmissionI, SubmissionReviewStatus } from '../../types';
import { ProgrammingAssignmentReviewDataContext } from '../review-admin-page-data-provider';

const sxStyles = {
    tagTitle: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    tagButton: (theme) => ({
        textDecoration: 'none',
        padding: theme.spacing(0, 1),
        whiteSpace: 'nowrap',
    }),
    missingAdminReview: {
        backgroundColor: '#ff983f',
    },
    inProgressAdminReview: {
        color: '#fff',
        backgroundColor: '#a836ad',
    },
    hasAdminReview: {
        color: '#fff',
        backgroundColor: '#388e3c',
    },
};

interface AdminReviewStatus {
    programmingAssignmentSubmission: SubmissionI | undefined;
    toggleStatusFilter: (status: SubmissionReviewStatus) => () => void;
}

const AdminReviewStatus = ({
    programmingAssignmentSubmission,
    toggleStatusFilter,
}: AdminReviewStatus) => {

    const DATA_CONTEXT = useContext(ProgrammingAssignmentReviewDataContext);

    if (!programmingAssignmentSubmission) return null;

    const createStatusFilterToggle = (type: SubmissionReviewStatus) => (
        event: React.MouseEvent,
    ) => {
        event.stopPropagation();
        const toggleCallback = toggleStatusFilter(type);
        toggleCallback();
    };

    const getStatus = (submission: SubmissionI) => {
        const reviewSubmissions = DATA_CONTEXT.programmingAssignmentReviewSubmissions;
        const adminReviewsForSubmission = reviewSubmissions.filter(
            (pars) => pars.programmingAssignmentSubmissionId === submission.id,
        );

        const completedAdminReview = adminReviewsForSubmission.find((pars) => !!pars.review);

        if (completedAdminReview) return 'hasAdminReview';

        const inProgressAdminReview = adminReviewsForSubmission.find((pars) => !pars.review);

        // console.log('BUU', submission, adminReviewsForSubmission, reviewSubmissions);
        return inProgressAdminReview ? 'inProgressAdminReview' : 'missingAdminReview';
    };

    const getStatusStyle = (submission: SubmissionI) => {
        const status = getStatus(submission);
        return (theme) => ({
            ...sxStyles[status],
            ...sxStyles.tagButton(theme)
        });
    };

    const getStatusText = (submission: SubmissionI) => {
        const status = getStatus(submission);
        return <Trans i18nKey={status} />;
    };

    return (
        <Box
            className="admin-review-status-tag-wrapper"
            flexDirection="row"
            alignItems="center"
            display="flex"
        >
            <Button
                color="inherit"
                disabled={DATA_CONTEXT.loading}
                onClick={createStatusFilterToggle(getStatus(programmingAssignmentSubmission))}
                sx={getStatusStyle(programmingAssignmentSubmission)}
            >
                {DATA_CONTEXT.loading
                    ? 'Loading...'
                    : getStatusText(programmingAssignmentSubmission)}
            </Button>
        </Box>
    );
};

export default AdminReviewStatus;
