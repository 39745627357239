import { Box, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { useContext, useState } from 'react';
import { getSubmissionForReviewZip, submitReview } from '../../../../http-actions/review-actions';

import { AuthContext } from '../../../../providers/auth-provider';
import ResultInfo from '../../../assignment/programming/result-info';
import PeerReviewForm from '../../review-form';

import { FormData } from '../../types';
import sxPageStyles from '../styles/page-styles';

interface PeerReviewFormContainerProps {
    programmingAssignmentReviewSubmissionId?: number;
    formData?: FormData;
    download?: string;
    onSubmitHook?: () => void;
}

export default function PeerReviewFormContainer({
    formData,
    programmingAssignmentReviewSubmissionId,
    download,
    onSubmitHook,
}: PeerReviewFormContainerProps) {
    const [resultInfoMessage, setResultInfoMessage] = useState(null);
    const { state: authState } = useContext(AuthContext);

    const onSubmit = async (data: unknown) => {
        if (!authState?.token || !programmingAssignmentReviewSubmissionId) return;

        // translate
        const toSubmit = confirm(
            'Are you sure you want to submit? The review cannot be changed later.',
        );
        if (toSubmit) {
            const response = await submitReview(
                authState.token,
                programmingAssignmentReviewSubmissionId,
                data,
            );

            // should call a passed onSubmit to refetch the reviewSubmissions
            if (onSubmitHook && typeof onSubmitHook === 'function') onSubmitHook();

            if (!response?.failureMessage) {
                setResultInfoMessage({ type: 'success', message: 'reviewSubmitted' });
            } else {
                setResultInfoMessage({
                    type: 'error',
                    message: 'unexpectedErrorOnSubmitReview',
                });
            }
        }
    };

    // TODO FIX NAME FOR FILE TO HAVE THE CURRENT REVIEW "INDEX"
    const triggerCodeZipDownload = () => {
        if (!authState?.token || !programmingAssignmentReviewSubmissionId) return;

        console.log('DOWNLOAD');
        void getSubmissionForReviewZip(
            authState.token,
            programmingAssignmentReviewSubmissionId,
        ).then((data: unknown) => {
            if (data?.err) return;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `review-submission.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    if (!(programmingAssignmentReviewSubmissionId && authState?.token && formData))
        return (
            <Box id="peer-review-form" display="flex" width="100%" px={2} my={2}>
                <Typography variant="subtitle1">Start review to show form</Typography>
            </Box>
        );

    return (
        <Box px={1} sx={sxPageStyles.listContainer}>
            <PeerReviewForm
                onSubmit={onSubmit}
                downloadCode={download && triggerCodeZipDownload}
                downloadLink={''}
                formData={formData}
            />
            {resultInfoMessage && (
                <ResultInfo
                    messageKey={resultInfoMessage?.message}
                    type={resultInfoMessage?.type}
                    endIcon={<Close />}
                    onClick={() => setResultInfoMessage(null)}
                />
            )}
        </Box>
    );
}
