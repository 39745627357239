import { IconButton, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { Trans } from 'react-i18next';
import React from 'react';


const PREFIX = 'ResultInfo';

const classes = {
    success: `${PREFIX}-success`,
    failure: `${PREFIX}-failure`,
    error: `${PREFIX}-error`,
    button: `${PREFIX}-button`,
    buttonText: `${PREFIX}-buttonText`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => ({
    [`& .${classes.success}`]: {
        backgroundColor: theme.palette.result.success,
        color: 'white',
    },

    [`& .${classes.failure}`]: {
        backgroundColor: theme.palette.result.fail,
        color: 'black',
    },

    [`& .${classes.error}`]: {
        backgroundColor: theme.palette.result.error,
        color: 'white',
    },

    [`& .${classes.button}`]: {
        padding: '16px',
        textTransform: 'none',
        width: '100%',
    },

    [`& .${classes.buttonText}`]: {
        flex: '1 1 auto',
    }
}));

interface InputProps {
    messageKey: string;
    className?: string;
    endIcon: JSX.Element;
    testResultComponents?: any;
    onClick: (event: React.MouseEvent) => void;
    type?: string;
}

const ResultInfo = (props: InputProps) => {

    let className = props.className;
    if (!className) {
        switch (props.type) {
            case 'success':
                className = classes.success; break;
            case 'failure':
                className = classes.failure; break;
            case 'error':
                className = classes.error; break;
            default:
                '';
        }
    }

    return (
        (<Root>
            <Paper className={className} style={{ margin: '0 0 16px 0' }}>
                <IconButton
                    className={`${classes.button} ${className}`}
                    edge="end"
                    size="small"
                    onClick={props.onClick}
                >
                    <Typography className={classes.buttonText}>
                        <Trans i18nKey={props.messageKey} />
                    </Typography>
                    {props.endIcon}
                </IconButton>
                {props.testResultComponents}
            </Paper>
        </Root>)
    );
};

export default ResultInfo;
