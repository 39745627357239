import React, { createContext, useContext, useEffect, useState, useMemo } from 'react';
import {
    getReviewAssignmentsAndProgrammingAssignments,
    getSubmissionsAvailableForReview,
    getReviewSubmissionsMadeByAdmins,
    assignCurrentUserAsReviewerForSubmission,
    removeAssignCurrentUserAsReviewerForSubmission,
} from '../../../http-actions/review-actions';

import { UserContext } from '../../../providers/user-provider';
import { AuthContext } from '../../../providers/auth-provider';
import { ProgrammingAssignmentI } from '../../help-request/types';
import { ProgrammingAssignmentReviewSubmissionI, ReviewAssignmentI, SubmissionI } from '../types';

interface ProgrammingAssignmentReviewDataContext {
    programmingAssignmentSubmissions: SubmissionI[];
    programmingAssignmentReviewSubmissions: ProgrammingAssignmentReviewSubmissionI[];
    selectedProgrammingAssignmentSubmission?: SubmissionI;
    setSelectedProgrammingAssignmentSubmission: React.Dispatch<
        React.SetStateAction<SubmissionI | undefined>
    >;
    selectedProgrammingAssignmentReviewSubmission?: ProgrammingAssignmentReviewSubmissionI;
    setSelectedProgrammingAssignmentReviewSubmission: React.Dispatch<
        React.SetStateAction<ProgrammingAssignmentReviewSubmissionI | undefined>
    >;
    initSubmissionData: () => void;
    programmingAssignmentSubmissionsLoading: boolean;
    programmingAssignmentReviewSubmissionsLoading: boolean;
    programmingAssignments: ProgrammingAssignmentI[];
    assignSubmissionToAdminForReview: (
        submission: SubmissionI,
    ) => Promise<
        | {
              reviewAssignmentReviewId: number;
              form: Record<string, unknown>;
          }
        | undefined
    >;
    removeAdminReviewAssignForSubmission: () => void;
    reviewSubmissionForCurrentUser?: ProgrammingAssignmentReviewSubmissionI;
    reviewAssignmentForCurrentUser?: ReviewAssignmentI;
    loading: boolean;
}

export const ProgrammingAssignmentReviewDataContext = createContext<
    ProgrammingAssignmentReviewDataContext
>({
    programmingAssignmentSubmissions: [],
    programmingAssignmentReviewSubmissions: [],
    selectedProgrammingAssignmentSubmission: undefined,
    setSelectedProgrammingAssignmentSubmission: () => undefined,
    selectedProgrammingAssignmentReviewSubmission: undefined,
    setSelectedProgrammingAssignmentReviewSubmission: () => undefined,
    initSubmissionData: () => undefined,
    programmingAssignmentSubmissionsLoading: false,
    programmingAssignmentReviewSubmissionsLoading: false,
    programmingAssignments: [],
    assignSubmissionToAdminForReview: async () => undefined,
    reviewSubmissionForCurrentUser: undefined,
    reviewAssignmentForCurrentUser: undefined,
    loading: true,
    removeAdminReviewAssignForSubmission: () => undefined,
});

export const ProgrammingAssignmentReviewAdminPageDataProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const { state: authState } = useContext(AuthContext);
    const { state: userState } = useContext(UserContext);
    console.log('US:', userState);

    // DATA LOADING
    const [reviewAssignmentsLoading, setReviewAssignmentsLoading] = useState(false);

    const [
        programmingAssignmentSubmissionsLoading,
        setProgrammingAssignmentSubmissionsLoading,
    ] = useState(false);
    const [
        programmingAssignmentReviewSubmissionsLoading,
        setProgrammingAssignmentReviewSubmissionsLoading,
    ] = useState(false);

    // DATA
    const [reviewAssignments, setReviewAssignments] = useState<ReviewAssignmentI[]>([]);

    const [programmingAssignments, setProgrammingAssignments] = useState<ProgrammingAssignmentI[]>(
        [],
    );

    const [programmingAssignmentSubmissions, setProgrammingAssignmentSubmissions] = useState<
        SubmissionI[]
    >([]);

    const [
        programmingAssignmentReviewSubmissions,
        setProgrammingAsignmentReviewSubmissions,
    ] = useState<ProgrammingAssignmentReviewSubmissionI[]>([]);

    // SELECTIONS
    const [
        selectedProgrammingAssignmentSubmission,
        setSelectedProgrammingAssignmentSubmission,
    ] = useState<SubmissionI | undefined>(undefined);

    const [
        selectedProgrammingAssignmentReviewSubmission,
        setSelectedProgrammingAssignmentReviewSubmission,
    ] = useState<ProgrammingAssignmentReviewSubmissionI | undefined>(undefined);

    // DATA FETCHING
    const fetchReviewAssignments = async () => {
        console.log('Fetch review assignments');
        if (!authState?.token) return;
        const response = await getReviewAssignmentsAndProgrammingAssignments(authState?.token);

        if (!response || response?.failureMessage) {
            return;
        }

        console.log('REVIEW ASSIGNMENTS', response);

        setReviewAssignments(response.reviewAssignments);
        setProgrammingAssignments(response.programmingAssignments);
    };

    const fetchSubmissions = async () => {
        console.log('Fetch submissions without review');
        if (!authState?.token) return;
        const assignmentIds = reviewAssignments.map((ra) => ra.assignmentId);

        const response = await getSubmissionsAvailableForReview(authState?.token, assignmentIds);
        if (!response || response?.failureMessage) return;

        console.log('SUBMISSIONS FOR ASSIGNMENT WITH REVIEW', response);

        setProgrammingAssignmentSubmissions(response);
    };

    const fetchReviewSubmissionsByAdmins = async () => {
        console.log('Fetch review submissions');
        if (!authState?.token) return;
        const assignmentIds = reviewAssignments.map((ra) => ra.assignmentId);

        const response = await getReviewSubmissionsMadeByAdmins(authState?.token, assignmentIds);
        if (!response || response?.failureMessage) return;

        console.log('REVIEW SUBMISSIONS FOR ASSIGNMENT', response);

        setProgrammingAsignmentReviewSubmissions(response);
    };

    const initReviewAssignmentData = () => {
        if (reviewAssignmentsLoading) return;

        setReviewAssignmentsLoading(true);

        void fetchReviewAssignments().finally(() => setReviewAssignmentsLoading(false));
    };

    const initSubmissionData = async () => {
        if (programmingAssignmentSubmissionsLoading) return;

        setProgrammingAssignmentSubmissionsLoading(true);
        void fetchSubmissions().finally(() => setProgrammingAssignmentSubmissionsLoading(false));

        if (programmingAssignmentReviewSubmissionsLoading) return;

        setProgrammingAssignmentReviewSubmissionsLoading(true);
        void fetchReviewSubmissionsByAdmins().finally(() =>
            setProgrammingAssignmentReviewSubmissionsLoading(false),
        );
    };

    const assignSubmissionToAdminForReview = async (submission: SubmissionI) => {
        console.log('Fetch submissions without review');
        if (!authState?.token) return;

        const submissionId = submission.id;
        const programmingAssignment = programmingAssignments.find(
            (pa) => pa.id === submission.programmingAssignmentId,
        );
        if (!programmingAssignment) return;
        const assignmentId = programmingAssignment.assignmentId;

        const response = await assignCurrentUserAsReviewerForSubmission(
            authState?.token,
            submissionId,
            assignmentId,
        );

        if (!response || response?.failureMessage) {
            return;
        }

        if (programmingAssignmentReviewSubmissionsLoading) return;

        setProgrammingAssignmentReviewSubmissionsLoading(true);
        void fetchReviewSubmissionsByAdmins().finally(() =>
            setProgrammingAssignmentReviewSubmissionsLoading(false),
        );

        return response as { reviewAssignmentReviewId: number; form: Record<string, unknown> };
    };

    const removeAdminReviewAssignForSubmission = async () => {
        if (!authState?.token) return;

        if (!reviewSubmissionForCurrentUser) return;

        await removeAssignCurrentUserAsReviewerForSubmission(
            authState?.token,
            reviewSubmissionForCurrentUser.id,
        );

        if (programmingAssignmentReviewSubmissionsLoading) return;

        setProgrammingAssignmentReviewSubmissionsLoading(true);
        void fetchReviewSubmissionsByAdmins().finally(() =>
            setProgrammingAssignmentReviewSubmissionsLoading(false),
        );
    };

    useEffect(() => {
        void initReviewAssignmentData();
    }, []);

    useEffect(() => {
        const assignmentsExist = reviewAssignments.length > 0;
        if (assignmentsExist) void initSubmissionData();
    }, [programmingAssignments]);

    const getReviewSubmissionForCurrentUser = () => {
        const checkForReviewSubmission =
            programmingAssignmentReviewSubmissions.length > 0 && Boolean(userState?.user?.id);
        if (!checkForReviewSubmission) return;

        const reviewSubmission = programmingAssignmentReviewSubmissions.find(
            (pars) => pars.userId === userState.user.id && !pars.review,
        );
        return reviewSubmission;
    };

    const reviewSubmissionForCurrentUser = useMemo(getReviewSubmissionForCurrentUser, [
        programmingAssignmentReviewSubmissions,
        userState,
    ]);

    const getReviewAssignmentForCurrentUser = () => {
        const checkForReviewAssignment =
            reviewAssignments.length > 0 && reviewSubmissionForCurrentUser;

        if (!checkForReviewAssignment) return;

        const reviewAssignment = reviewAssignments.find(
            (ra) => ra.id === reviewSubmissionForCurrentUser?.reviewAssignmentId,
        );
        return reviewAssignment;
    };

    const reviewAssignmentForCurrentUser = useMemo(getReviewAssignmentForCurrentUser, [
        reviewAssignments,
        reviewSubmissionForCurrentUser,
    ]);

    const loading =
        programmingAssignmentReviewSubmissionsLoading || programmingAssignmentSubmissionsLoading;

    const contextValue: ProgrammingAssignmentReviewDataContext = {
        programmingAssignmentSubmissions,
        programmingAssignmentReviewSubmissions,
        selectedProgrammingAssignmentSubmission,
        setSelectedProgrammingAssignmentSubmission,
        selectedProgrammingAssignmentReviewSubmission,
        setSelectedProgrammingAssignmentReviewSubmission,
        initSubmissionData,
        programmingAssignmentSubmissionsLoading,
        programmingAssignmentReviewSubmissionsLoading,
        programmingAssignments,
        assignSubmissionToAdminForReview,
        removeAdminReviewAssignForSubmission,
        reviewSubmissionForCurrentUser,
        reviewAssignmentForCurrentUser,
        loading,
    };

    return (
        <ProgrammingAssignmentReviewDataContext.Provider value={contextValue}>
            {children}
        </ProgrammingAssignmentReviewDataContext.Provider>
    );
};

export default ProgrammingAssignmentReviewAdminPageDataProvider;
