import { Box, Button, ListItemButton, Typography } from '@mui/material';
import React, { useContext } from 'react';
import CustomButton from '../../../button';
import { SubmissionI } from '../../types';
import { ProgrammingAssignmentReviewDataContext } from '../review-admin-page-data-provider';
import AdminReviewStatus from './admin-reivew-status';

interface HelpRequestListItemProps {
    programmingAssignmentSubmission: SubmissionI;
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
    selected?: boolean;
    toggleUserFilter?: (id: string) => () => void;
    toggleProgrammingAssignmentFilter?: (id: string) => () => void;
    mode: 'inspect' | 'review';
}

const ProgrammingAssignmentSubmissionListItem = ({
    programmingAssignmentSubmission,
    selected,
    mode,
    onClick,
}: HelpRequestListItemProps) => {
    const DATA_CONTEXT = useContext(ProgrammingAssignmentReviewDataContext);

    const loading = DATA_CONTEXT.programmingAssignmentSubmissionsLoading;
    const programmingAssignments = DATA_CONTEXT.programmingAssignments;
    const assignToSelfAction = DATA_CONTEXT.assignSubmissionToAdminForReview;
    const removeAssignToSelfAction = DATA_CONTEXT.removeAdminReviewAssignForSubmission;

    const programmingAssignmentForListItem = programmingAssignments.find(
        (pa) => pa.id === programmingAssignmentSubmission?.programmingAssignmentId,
    );

    const getStatus = (submission: SubmissionI) => {
        const reviewSubmissions = DATA_CONTEXT.programmingAssignmentReviewSubmissions;
        const adminReviewsForSubmission = reviewSubmissions.filter(
            (pars) => pars.programmingAssignmentSubmissionId === submission.id,
        );

        const completedAdminReview = adminReviewsForSubmission.find((pars) => !!pars.review);

        if (completedAdminReview) return 'hasAdminReview';

        const inProgressAdminReview = adminReviewsForSubmission.find((pars) => !pars.review);

        // console.log('BUU', submission, adminReviewsForSubmission, reviewSubmissions);
        return inProgressAdminReview ? 'inProgressAdminReview' : 'missingAdminReview';
    };

    const submissionHasAdminReview =
        'hasAdminReview' === getStatus(programmingAssignmentSubmission);

    const currentUserHasPendingReview = !!DATA_CONTEXT.reviewSubmissionForCurrentUser;

    const currentUserReviewingSubmission =
        currentUserHasPendingReview &&
        DATA_CONTEXT.reviewSubmissionForCurrentUser?.programmingAssignmentSubmissionId ===
            programmingAssignmentSubmission?.id;

    const canAssignToSelf =
        !DATA_CONTEXT.loading && !currentUserHasPendingReview && !submissionHasAdminReview;

    const canRemoveSelfAssignment = !DATA_CONTEXT.loading && currentUserReviewingSubmission;

    return (
        <>
            <ListItemButton
                id={`programming-assignment-submission-list-item-${programmingAssignmentSubmission.id}`}
                key={programmingAssignmentSubmission.id}
                divider
                disableRipple={mode === 'review'}
                disableTouchRipple={mode === 'review'}
                onClick={onClick ? onClick : () => {}}
                disabled={loading}
                selected={selected}
            >
                <Box
                    data-e2e-hook={`programming-assignment-submission-header-${programmingAssignmentSubmission.helpRequestUuid}`}
                    className="programming-assignment-submission-row"
                    display="flex"
                    width="100%"
                    alignItems="flex-end"
                >
                    <Box
                        className="programming-assignment-submission-header"
                        flexDirection="column"
                        display="flex"
                        width="100%"
                        flexGrow="3"
                    >
                        <Typography
                            variant="h3"
                            className="programming-assignment-submission-title"
                            gutterBottom
                        >
                            {`${programmingAssignmentForListItem?.title || 'Loading...'}`}
                        </Typography>
                        <Typography className="programming-assignment-submission-user-id-subtitle">
                            {`User id: ${programmingAssignmentSubmission.userId}`}
                        </Typography>
                        <Typography className="programming-assignment-submission-user-id-subtitle" onClick={async () => {
                            navigator && navigator.clipboard && await navigator.clipboard.writeText(programmingAssignmentSubmission.userUuid);
                        }}>
                            {programmingAssignmentSubmission.userUuid}
                        </Typography>
                        <Typography
                            mb={1}
                            className="programming-assignment-submission-review-count-subtitle"
                        >
                            {`Peer-review count: ${
                                programmingAssignmentSubmission?.submission_review_count || '?'
                            }`}
                        </Typography>
                        <AdminReviewStatus
                            programmingAssignmentSubmission={programmingAssignmentSubmission}
                            toggleStatusFilter={() => () => undefined}
                        />
                    </Box>
                </Box>
                <Box
                    display="flex"
                    flexGrow="2"
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    height="100%"
                >
                    <Box
                        className="programming-assignment-submission-created-at"
                        textAlign="right"
                        mb={1}
                    >
                        <Typography variant="subtitle1">
                            {new Date(
                                programmingAssignmentSubmission.createdAt,
                            ).toLocaleDateString()}
                        </Typography>
                        <Typography variant="subtitle2">
                            {new Date(
                                programmingAssignmentSubmission.createdAt,
                            ).toLocaleTimeString()}
                        </Typography>
                    </Box>
                    <Box
                        className="button-container"
                        display="flex"
                        minWidth="150px"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                    >
                        {canAssignToSelf && mode === 'review' && (
                            <CustomButton
                                variant="contained"
                                color="fitech"
                                disableElevation
                                onClick={() => assignToSelfAction(programmingAssignmentSubmission)}
                            >
                                Start review
                            </CustomButton>
                        )}
                        {canRemoveSelfAssignment && mode === 'review' && (
                            <CustomButton
                                variant="contained"
                                color="secondary"
                                disableElevation
                                onClick={() => {
                                    const stopReviewConfirmation = confirm(
                                        'Stopping the review will delete the progress of the ongoing review. Do you want to continue?',
                                    );
                                    if (stopReviewConfirmation) removeAssignToSelfAction();
                                }}
                            >
                                Stop review
                            </CustomButton>
                        )}
                    </Box>
                </Box>
            </ListItemButton>
        </>
    );
};

export default ProgrammingAssignmentSubmissionListItem;
