import { Box, Typography, Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import React, { useContext, useState } from 'react';
import RestrictedByRole from '../../components/restricted-by-role';
import RefreshButton from '../../components/help-request/button/refresh-button';
import sxPageStyles from '../../components/peer-review/admin/styles/page-styles';
import ProgrammingAssignmentReviewAdminPageDataProvider, {
    ProgrammingAssignmentReviewDataContext,
} from '../../components/peer-review/admin/review-admin-page-data-provider';
import ProgrammingAssignmentReviewAdminPageFilterProvider from '../../components/peer-review/admin/review-admin-page-filter-provider';
import ProgrammingAssignmentSubmissionListContainer from '../../components/peer-review/admin/view/programming-assignment-submission-list-container';
import PeerReviewFormContainer from '../../components/peer-review/admin/view/peer-review-form-container';
import PeerReviewViewer from '../../components/peer-review/admin/view/peer-review-viewer';
import ProjectSubmissionDownloadButton from '../../components/peer-review/admin/view/project-submission-download-button';

const ProgrammingAssignmentReviewAdminPage = () => {
    const {
        loading,
        initSubmissionData,
        reviewSubmissionForCurrentUser,
        reviewAssignmentForCurrentUser,
        selectedProgrammingAssignmentSubmission,
        programmingAssignmentReviewSubmissions,
        programmingAssignments,
    } = useContext(ProgrammingAssignmentReviewDataContext);

    const [mode, setMode] = useState<string | null>('review');

    const handleModeChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string | null,
    ) => {
        if (newAlignment === null) return;
        setMode(newAlignment);
    };

    console.log(
        'HEHE',
        selectedProgrammingAssignmentSubmission,
        programmingAssignmentReviewSubmissions,
        programmingAssignments,
    );

    return (
        <Box component={'main'} sx={sxPageStyles.root}>
            <Box sx={sxPageStyles.evenRatioColumnLayout}>
                <Box component="section" sx={sxPageStyles.evenRatioColumn}>
                    <Box sx={sxPageStyles.columnContent}>
                        <Paper elevation={2} sx={sxPageStyles.helpRequestList}>
                            <Box sx={sxPageStyles.listTitle}>
                                <Box sx={sxPageStyles.contentRefreshButton}>
                                    <RefreshButton loading={loading} onClick={initSubmissionData} />
                                </Box>
                                <Typography variant="h2">Project submissions</Typography>
                                <Box display="flex" justifyContent="flex-end" flexGrow="1">
                                    <ToggleButtonGroup
                                        color="fitech"
                                        value={mode}
                                        exclusive
                                        onChange={handleModeChange}
                                        aria-label="Review mode or viewing mode"
                                    >
                                        <ToggleButton value="inspect">Inspection mode</ToggleButton>
                                        <ToggleButton value="review">Review mode</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Box>
                            <Box sx={sxPageStyles.listFilterContainer}>
                                <ProgrammingAssignmentSubmissionListContainer mode={mode} />
                            </Box>
                        </Paper>
                    </Box>
                </Box>
                {mode === 'inspect' && (
                    <Box component="section" sx={sxPageStyles.evenRatioColumn}>
                        <Box sx={sxPageStyles.columnContent}>
                            <Paper elevation={2} sx={sxPageStyles.helpRequestList}>
                                <Box px={1} sx={sxPageStyles.listContainer}>
                                    {selectedProgrammingAssignmentSubmission ? (
                                        <Box p={2}>
                                            <ProjectSubmissionDownloadButton
                                                programmingAssignmentSubmissionId={
                                                    selectedProgrammingAssignmentSubmission?.id
                                                }
                                            />
                                            <PeerReviewViewer
                                                programmingAssignmentSubmissionId={
                                                    selectedProgrammingAssignmentSubmission?.id
                                                }
                                            />
                                        </Box>
                                    ) : (
                                        <Box p={2}>
                                            <Typography>
                                                Select submission to inspect existing reviews
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Paper>
                        </Box>
                    </Box>
                )}
                {mode === 'review' && (
                    <Box component="section" sx={sxPageStyles.evenRatioColumn}>
                        <Box sx={sxPageStyles.columnContent}>
                            <Paper elevation={2} sx={sxPageStyles.helpRequestList}>
                                <PeerReviewFormContainer
                                    programmingAssignmentReviewSubmissionId={
                                        reviewSubmissionForCurrentUser?.id
                                    }
                                    formData={reviewAssignmentForCurrentUser?.form}
                                    download="peer"
                                    onSubmitHook={initSubmissionData}
                                />
                            </Paper>
                        </Box>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

const ProgrammingAssignmentReviewAdminPageWithDataAndFilterProvider = (
    props: JSX.IntrinsicAttributes,
) => (
    <ProgrammingAssignmentReviewAdminPageDataProvider>
        <ProgrammingAssignmentReviewAdminPageFilterProvider>
            <ProgrammingAssignmentReviewAdminPage {...props} />
        </ProgrammingAssignmentReviewAdminPageFilterProvider>
    </ProgrammingAssignmentReviewAdminPageDataProvider>
);

const ProjectReviewAdminPageWithRestriction = (props: JSX.IntrinsicAttributes) => (
    <RestrictedByRole role="ADMIN">
        <ProgrammingAssignmentReviewAdminPageWithDataAndFilterProvider {...props} />
    </RestrictedByRole>
);

export default ProjectReviewAdminPageWithRestriction;
