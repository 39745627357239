import { Box, Divider, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight, Close } from '@mui/icons-material';
import React, { useContext, useEffect, useState } from 'react';

import { getReviewsForSubmission } from '../../../../http-actions/review-actions';
import { AuthContext } from '../../../../providers/auth-provider';
import ResultInfo from '../../../assignment/programming/result-info';
import Button from '../../../button';
import FormResult from '../../form-result';

const sxStyles = {
    root: (theme) => ({
        padding: theme.spacing(2, 0),
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
    }),
};

interface InputProps {
    programmingAssignmentSubmissionId?: number;
}

export default function PeerReviewViewer({ programmingAssignmentSubmissionId }: InputProps) {
    const [data, setData] = useState<
        { form: any; peerReviews: any; totalReviews: any } | undefined
    >(undefined);
    const [resultInfoMessage, setResultInfoMessage] = useState();
    const [reviewIndex, setReviewIndex] = useState(0);

    const { state: authState } = useContext(AuthContext);

    console.log('ASS', programmingAssignmentSubmissionId);

    useEffect(() => {
        setReviewIndex(0);
        fetchdata();
    }, [authState, programmingAssignmentSubmissionId]);

    const fetchdata = async () => {
        console.log('fetching form data');
        if (!programmingAssignmentSubmissionId || !authState?.token) return;

        const reviewData = await getReviewsForSubmission(
            authState.token,
            programmingAssignmentSubmissionId,
        );

        if (reviewData.form && reviewData.peerReviews) {
            setData({
                form: reviewData.form,
                peerReviews: reviewData.peerReviews,
                totalReviews: reviewData.peerReviews.length,
            });
            setResultInfoMessage(undefined);
        } else {
            setData(undefined);
            reviewData.failureMessage &&
                setResultInfoMessage({
                    type: 'failure',
                    message: reviewData.failureMessage,
                }); // TODO: localization
            reviewData.err &&
                setResultInfoMessage({ type: 'error', message: reviewData.err.toString() });
        }
    };

    const handleNavigation = (direction: string) => {
        switch (direction) {
            case 'next': {
                if (data?.totalReviews > 0 && reviewIndex < data?.totalReviews - 1) {
                    setReviewIndex((prevState) => prevState + 1);
                }
                break;
            }
            case 'previous': {
                if (data?.totalReviews > 0 && reviewIndex > 0) {
                    setReviewIndex((prevState) => prevState - 1);
                }
                break;
            }
            default: {
                return;
            }
        }
    };

    console.log('DDDD', data);

    return (
        <>
            {data?.peerReviews?.length > 0 ? (
                <>
                    <Box component={'nav'} id="review-viewer-navigation" sx={sxStyles.root}>
                        <Button
                            kind="fitech"
                            elevation={0}
                            onClick={() => handleNavigation('previous')}
                            disabled={reviewIndex === 0}
                        >
                            <ArrowLeft fontSize="large" />
                        </Button>

                        <Box
                            sx={{
                                gridColumn: '2 / 3',
                                alignSelf: 'center',
                                justifySelf: 'center',
                            }}
                        >
                            {reviewIndex + 1} / {data?.totalReviews}
                        </Box>

                        <Button
                            kind="fitech"
                            elevation={0}
                            onClick={() => handleNavigation('next')}
                            disabled={reviewIndex === data?.totalReviews - 1}
                        >
                            <ArrowRight fontSize="large" />
                        </Button>
                    </Box>
                    <Box py={1}>
                        <Typography variant="subtitle2" color="fitech">
                            {`Reviewed by: ${
                                data && data.peerReviews[reviewIndex]?.is_admin_review
                                    ? 'Admin'
                                    : 'Peer'
                            }`}
                        </Typography>
                    </Box>
                    <Divider sx={{ height: '1px', my: 1 }} />
                    {data && (
                        <FormResult
                            formData={data.form}
                            reviewId={data.peerReviews[reviewIndex]?.id}
                            formState={data.peerReviews[reviewIndex]?.review}
                            isAdmin={true}
                        />
                    )}
                </>
            ) : (
                <Box sx={{ width: '100%', p: 2 }}>
                    <Typography>There are no reviews for this submission</Typography>
                </Box>
            )}
            {resultInfoMessage && (
                <ResultInfo
                    messageKey={resultInfoMessage.message}
                    type={resultInfoMessage.type}
                    endIcon={<Close />}
                    onClick={() => setResultInfoMessage(undefined)}
                />
            )}
        </>
    );
}
