import {
    Box,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    FormLabel,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowRight, FiberManualRecord, Remove } from '@mui/icons-material';
import React from 'react';

import { FieldItem, FieldType } from './types';

export default function DynamicField({
    register,
    errors,
    formState,
    // control, // can be useful when adding a new type of input field
    fieldProps,
}: // setValue, // may be used for
{
    register: any;
    errors: any;
    setValue: any;
    formState: any;
    control: any;
    fieldProps: FieldItem;
}) {
    const { name, content, question, description, legend, options, type, componentProps } =
        fieldProps || {};

    const { defaultValue, multiline, rows, fullWidth, min, max, step, required, inputProps } =
        componentProps || {};

    // console.log('Dynamic form field', fieldProps);
    // console.log('ERR', errors);

    switch (type) {
        case FieldType.Description: {
            return (
                <Box mb={2}>
                    <Typography id={name} variant="body1" gutterBottom>
                        {content}
                    </Typography>
                </Box>
            );
        }
        case FieldType.TextField: {
            return (
                <Box mb={2}>
                    <Typography variant="body1" gutterBottom>
                        {question}
                    </Typography>
                    {description && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {description}
                        </Typography>
                    )}
                    <TextField
                        onChange={() => 0}
                        id={name}
                        name={name}
                        // inputRef={register({ required: required || false })}
                        // inputRef={register}
                        multiline={multiline || false}
                        rows={rows || undefined}
                        aria-labelledby={name}
                        defaultValue={defaultValue || ''}
                        variant="outlined"
                        fullWidth={fullWidth || true}
                        required={required || false}
                        inputProps={inputProps}
                        {...register(`${name}`)}
                    />
                </Box>
            );
        }
        case FieldType.Radio: {
            return (
                <Box mb={2}>
                    {question && (
                        <Typography id="question" variant="body1" gutterBottom>
                            {question}
                        </Typography>
                    )}
                    {description && (
                        <Typography variant="body2" color="textSecondary" gutterBottom>
                            {description}
                        </Typography>
                    )}
                    <FormControl required={required} error={!!errors[name]} component="fieldset">
                        {legend && <FormLabel component="legend">{legend}</FormLabel>}
                        <RadioGroup aria-label={legend}>
                            {options?.map((option, i, arr) => (
                                <FormControlLabel
                                    key={name + option.label}
                                    name={name}
                                    {...register(`${name}`, { required: required })}
                                    // inputRef={
                                    //     arr.length === i + 1
                                    //        ? register({ required: required })
                                    //        : register
                                    // }
                                    value={option.value}
                                    label={option.label}
                                    control={<Radio />}
                                />
                            ))}
                        </RadioGroup>
                        <FormHelperText>
                            {errors[name] && (errors[name].message || 'Please select an option.')}
                        </FormHelperText>
                    </FormControl>
                </Box>
            );
        }
        case FieldType.Checkbox: {
            return (
                <Box mb={2}>
                    {/* (NEED TO) VALIDATE THE CHECKED STATUS MANUALLY */}
                    <FormControl required={required} component="fieldset">
                        {legend && <FormLabel component="legend">{legend}</FormLabel>}
                        <FormGroup>
                            {options?.map((option, parentIndex) => {
                                const parent = option.children && option.children.length > 0;

                                // REWRITE TO NOT BE SO EXPENSIVE
                                // USE THE CHILD NAMES TO FETCH THE CHECKED STATUS
                                const parentChecked: boolean | undefined = false;

                                // if (parent) {
                                //     const opt = option.children
                                //         .map(
                                //             (_, childIndex) =>
                                //                 `${name}-parent-${parentIndex}-child-${childIndex}`,
                                //         )
                                //         .map((name) => formState[name]);

                                //     parentChecked = opt.length > 0 && opt.every((value) => value);

                                //     console.log('PARENT', option.label);
                                //     console.log('OPTIONS', opt);
                                //     console.log('Check: ', parentChecked);
                                // }

                                // Check children when parent checked
                                // const handleParentCheck = (event: any) => {
                                //     console.log(event.target.checked);
                                //     option.children?.forEach((child, childIndex) => {
                                //         setValue(
                                //             `${name}-parent-${parentIndex}-child-${childIndex}`,
                                //             event.target.checked,
                                //             { shouldDirty: true },
                                //         );
                                //     });
                                // };

                                return (
                                    <React.Fragment key={`${name}-parent-${parentIndex}`}>
                                        <FormControlLabel sx={{ mb: 1 }}
                                            control={
                                                parent ? (
                                                    <Checkbox
                                                        name={`${name}-parent-${parentIndex}`}
                                                        color="primary"
                                                        // inputRef={register}
                                                        checked={false}
                                                        // defaultChecked={false}
                                                        // onChange={handleParentCheck}
                                                        disableFocusRipple
                                                        indeterminate={true}
                                                        disableRipple
                                                        inputProps={{
                                                            'aria-label': `${name}-parent-${parentIndex}-checkbox`,
                                                        }}
                                                        {...register(`${name}-parent-${parentIndex}`)}
                                                    />
                                                ) : (
                                                    <Checkbox
                                                        name={`${name}-parent-${parentIndex}`}
                                                        color="primary"
                                                        // inputRef={register}
                                                        inputProps={{
                                                            'aria-label': `${name}-parent-${parentIndex}-checkbox`,
                                                        }}
                                                        {...register(`${name}-parent-${parentIndex}`)}
                                                    />
                                                )
                                            }
                                            label={option.label}
                                        />
                                        {option.description && (
                                            <Typography
                                                sx={{ py: 1, px: 3 }}
                                                variant="body2"
                                                color="textSecondary"
                                            >
                                                {option.description}
                                            </Typography>
                                        )}
                                        {option.children?.map((child, childIndex) => (
                                            <Box
                                                pl={2}
                                                key={`${name}-parent-${parentIndex}-child-${childIndex}`}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            name={`${name}-parent-${parentIndex}-child-${childIndex}`}
                                                            color="primary"
                                                            size="small"
                                                            // inputRef={register}
                                                            {...register(`${name}-parent-${parentIndex}-child-${childIndex}`)}
                                                            defaultChecked={
                                                                option.defaultChecked || false
                                                            }
                                                            inputProps={{
                                                                'aria-label': `${name}-parent-${parentIndex}-child-${childIndex}-checkbox`,
                                                            }}
                                                        />
                                                    }
                                                    label={child.label}
                                                />
                                                {/* eslint-disable indent */}
                                                {child.description
                                                    ? Array.isArray(child.description) && (
                                                          <List dense disablePadding>
                                                              {child.description.map(
                                                                  (item, index) => (
                                                                      <ListItem
                                                                          key={`${item}-${index}`}
                                                                          sx={{ px: 2 }}
                                                                          disableGutters
                                                                      >
                                                                          <ListItemIcon>
                                                                              <Remove />
                                                                          </ListItemIcon>
                                                                          <ListItemText
                                                                              primary={item}
                                                                          />
                                                                      </ListItem>
                                                                  ),
                                                              )}
                                                          </List>
                                                      )
                                                    : typeof child.description === 'string' && (
                                                          <Typography
                                                              sx={{ px: 2 }}
                                                              variant="body2"
                                                              color="textSecondary"
                                                          >
                                                              {child.description}
                                                          </Typography>
                                                      )}
                                                {/* eslint-enable indent */}
                                            </Box>
                                        ))}
                                    </React.Fragment>
                                );
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
            );
        }
        case FieldType.Divider: {
            return (
                <Box mb={2}>
                    <Divider />
                </Box>
            );
        }
        default:
            return (
                <Box my={2}>
                    <Typography id="unknown-field-type" color="error">
                        Unknown field type. Please specify a valid field type.
                    </Typography>
                </Box>
            );
    }
}
