import { Box, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { getProgrammingAssignmentSubmissionFile } from '../../../../http-actions/assignment-actions';
import { AuthContext } from '../../../../providers/auth-provider';
import CustomButton from '../../../button';

interface Props {
    programmingAssignmentSubmissionId?: number;
}

export default function ProjectSubmissionDownloadButton({
    programmingAssignmentSubmissionId,
}: Props) {
    const { state: authState } = useContext(AuthContext);

    const triggerCodeZipDownload = () => {
        if (!authState?.token || !programmingAssignmentSubmissionId) return;

        console.log('DOWNLOAD');
        void getProgrammingAssignmentSubmissionFile(
            authState.token,
            programmingAssignmentSubmissionId,
        ).then((data: unknown) => {
            if (data?.err) return;
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `submission.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };

    return (
        <>
            <Typography id="download-project" variant="h3">
                Download submission
            </Typography>
            <Typography id="download-project-description" color="textSecondary" gutterBottom>
                Click the button below to download a zip-file containing the code for this review.
            </Typography>

            <Box sx={{ mt: 2, mb: 4 }}>
                <CustomButton
                    kind="fitech"
                    variant="contained"
                    color="fitech"
                    elevation={0}
                    onClick={triggerCodeZipDownload}
                >
                    Download project
                </CustomButton>
            </Box>
        </>
    );
}
