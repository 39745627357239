import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.ASSIGNMENT_API_BASE_URL);

// UPDATE THE ACTIONS TO REFLECT CHANGES IN THE ASSIGNMENT BACKEND

export const getPeerReviewFormByAssignmentUuid = (token: string, assignmentUuid: string) => {
    console.log(`requesting review for user ${token}`);
    return axios
        .get(`api/reviews/${assignmentUuid}/peer-review`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getSelfReviewFormByAssignmentUuid = (token: string, assignmentUuid: string) => {
    console.log(`requesting review for user ${token}`);
    return axios
        .get(`api/reviews/${assignmentUuid}/self-review`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getReviewAssignment = (token: string, assignmentUuid: string) => {
    console.log(`requesting submitted reviews for user ${token}`);
    return axios
        .get(`api/reviews/${assignmentUuid}/overview`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getAssignmentReviews = (token: string, assignmentUuid: string) => {
    console.log(`requesting submitted reviews for user ${token}`);
    return axios
        .get(`api/reviews?assignmentUuid=${assignmentUuid}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const submitReview = (
    token: string,
    reviewAssignmentReviewId: number,
    formResponse: any,
) => {
    console.log(
        `requesting review update for review ${reviewAssignmentReviewId} with auth token ${token}`,
    );
    return axios
        .post(
            `api/reviews/submit/${reviewAssignmentReviewId}`,
            { formResponse: formResponse },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const getSubmissionForReviewZip = (token: string, reviewAssignmentReviewId: number) => {
    console.log(`requesting programming submission for review ${reviewAssignmentReviewId}...`);
    return axios
        .get(`api/reviews/${reviewAssignmentReviewId}/download/submission`, {
            headers: { Authorization: token },
            responseType: 'blob',
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getReviewsFromPeers = (token: string, assignmentUuid: string) => {
    console.log(`requesting reviews for submission by user ${token}`);
    return axios
        .get(`api/reviews/${assignmentUuid}/reviews-to-self`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getReviewsForSubmission = (token: string, submissionId: number) => {
    console.log(`requesting reviews for submission by user ${token}`);
    return axios
        .get(`api/reviews/submission/${submissionId}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getPeerReviewFormForAdminByAssignmentUuid = (
    token: string,
    assignmentUuid: string,
) => {
    console.log(`requesting review for user ${token}`);
    return axios
        .get(`api/reviews/${assignmentUuid}/admin-review`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getReviewAssignmentsAndProgrammingAssignments = (token: string) => {
    console.log(`requesting review-assignments`);
    return axios
        .get(`api/reviews/assignments/admin`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getSubmissionsAvailableForReview = (token: string, assignmentIds: number[]) => {
    console.log(`requesting submissions for assignments ${assignmentIds} with auth token ${token}`);
    return axios
        .post(
            `api/reviews/programming-assignment-submissions/admin`,
            { assignmentIds },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const getReviewSubmissionsMadeByAdmins = (token: string, assignmentIds: number[]) => {
    console.log(`requesting review submissions for assignments ${assignmentIds} made by admins`);
    return axios
        .post(
            `api/reviews/submissions/admin`,
            { assignmentIds },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const assignCurrentUserAsReviewerForSubmission = (
    token: string,
    submissionId: number,
    assignmentId: number,
) => {
    console.log(
        `assigning user with token ${token} as reviewer for submission with id ${submissionId}`,
    );
    return axios
        .post(
            `api/reviews/submissions/assign/admin`,
            { submissionId, assignmentId },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const removeAssignCurrentUserAsReviewerForSubmission = (
    token: string,
    reviewSubmissionId: number,
) => {
    console.log(
        `removing assignment of user with token ${token} for review submission with id ${reviewSubmissionId}`,
    );
    return axios
        .post(
            `api/reviews/submissions/remove-assign/admin`,
            { reviewSubmissionId },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};
