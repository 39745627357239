const sxStyles = {
    root: {
        height: 'calc(100vh - 64px)',
        overflowY: 'hidden',
    },
    dashboardContainer: {
        height: '100%',
        display: 'grid',
        gridTemplateRows: '1fr 5fr',
    },
    summaryContainer: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
        flexWrap: 'wrap',
    }),
    editorContainer: {
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    oneTwoRatioColumnLayout: {
        display: 'grid',
        gridTemplateColumns: 'minmax(300px, 1fr) auto 2fr',
        height: '100%',
        overflowY: 'hidden',
    },
    evenRatioColumnLayout: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        height: '100%',
        overflowY: 'hidden',
    },
    evenRatioColumn: {
        overflowY: 'hidden',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    columnContent: (theme) => ({
        height: '100%',
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: theme.spacing(1),
    }),
    oneTwoRatioLeftColumn: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
    },
    oneTwoRatioRightColumn: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflowY: 'hidden',
    },
    helpRequestList: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
    },
    listFilterContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    accordionHeading: (theme) => ({
        fontSize: `${15 / 16}rem`,
        flexBasis: '33.33%',
        flexShrink: 0,
    }),
    accordionSecondaryHeading: (theme) => ({
        fontSize: `${15 / 16}rem`,
        color: theme.palette.text.secondary,
    }),
    filterContentWrapper: (theme) => ({
        padding: theme.spacing(0),
    }),
    filterContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    listTitle: (theme) => ({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(2, 2, 0),
        marginBottom: theme.spacing(0),
    }),
    listContainer: {
        height: '100%',
        overflowY: 'scroll',
    },
    contentRefreshButton: (theme) => ({
        width: '48px',
        height: '48px',
        marginRight: theme.spacing(1),
    }),
    summaryTitle: (theme) => ({
        margin: theme.spacing(1),
    }),
    summaryContent: (theme) => ({
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        margin: theme.spacing(0, 1),
    }),
    statusCategory: (theme) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: '190px',
        margin: theme.spacing(1),
    }),
    pending: {
        backgroundColor: '#ff983f',
    },
    notResolved: {
        backgroundColor: '#ff983f',
    },
    notResponded: {
        backgroundColor: '#ff983f',
    },
    pendingCheckbox: {
        color: '#ff983f',
        '&checked': {
            color: '#ff983f',
        },
    },
    inProgress: {
        color: '#fff',
        backgroundColor: '#a836ad',
    },
    inProgressCheckbox: {
        color: '#a836ad',
        '&checked': {
            color: '#a836ad',
        },
    },
    isResolved: {
        color: '#fff',
        backgroundColor: '#388e3c',
    },
    resolved: {
        color: '#fff',
        backgroundColor: '#388e3c',
    },
    resolvedCheckbox: {
        color: '#388e3c',
        '&checked': {
            color: '#388e3c',
        },
    },
    responded: {
        backgroundColor: '#168AFD',
    },
    respondedCheckbox: {
        color: '#168AFD',
        '&checked': {
            color: '#168AFD',
        },
    },
    statusTitle: (theme) => ({
        color: 'inherit',
        marginLeft: theme.spacing(2),
    }),
    statusMoreInfo: {
        color: 'inherit',
    },
    formControl: {
        width: '100%',
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    templateSelect: (theme) => ({
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
    }),
    categoryFilter: (theme) => ({
        padding: theme.spacing(0, 2),
        margin: theme.spacing(1, 0),
    }),
    dateRangePicker: (theme) => ({
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
    }),
    clearDateButton: (theme) => ({
        margin: theme.spacing(0, 1),
    }),
    userSelect: (theme) => ({
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
    }),
    programmingAssignmentSelect: (theme) => ({
        padding: theme.spacing(0, 2),
        marginBottom: theme.spacing(1),
    }),
    categoryCheckbox: {},
    helpRequestContent: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    inputAdornmentHidden: {
        display: 'none',
    },
};

export default sxStyles;
