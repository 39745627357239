export interface FormSection {
    name: string;
    items: Array<FieldItem>;
}

export interface FormData {
    header: string;
    sections: Array<FormSection>;
}

export enum FieldType {
    TextField = 'TextField',
    Slider = 'Slider',
    Radio = 'Radio',
    Checkbox = 'Checkbox',
    Description = 'Description',
    Divider = 'Divider',
}

export interface FormSection {
    name: string;
    items: Array<FieldItem>;
}

export interface InputProps {
    maxLength?: number;
    minLength?: number;
}
export interface ComponentProps {
    defaultValue?: string | number;
    multiline?: boolean;
    rows?: number;
    fullWidth?: boolean;
    min?: number;
    max?: number;
    step?: number;
    required?: boolean;
    inputProps?: InputProps;
}

export interface FormOptions {
    label: string;
    value?: number | string;
    defaultChecked?: boolean;
    description?: string | Array<string>;
    children?: Array<FormOptions>;
}

export interface FieldItem {
    name: string; // all, should be snake case and unique to work as an identifier
    content?: string; // description
    question?: string; // all
    description?: string; // all
    legend?: string; // radio and checkbox
    options?: Array<FormOptions>; // radio and checkbox
    parent?: boolean; // checkbox is parent
    lastChild?: boolean; // information for adding bottom margin to the end of a group of nested checkboxes
    indentation?: number; // number of parents for checkboxes (0: no parents, 1: single parent, etc.)
    type: string; // FieldType, typescript does not validate at runtime and thus we assume it is a string
    componentProps?: ComponentProps;
}

export interface ProgrammingAssignmentReviewSubmissionI {
    id: number;
    reviewAssignmentId: number;
    userId: number;
    programmingAssignmentSubmissionId: number;
    review: Record<string, unknown>;
    createdAt: string;
    updatedAt: string;
    is_self_review?: boolean;
    submission_user_id?: number;
}

export interface ReviewAssignmentI {
    id: number;
    assignmentId: number;
    self_review: boolean;
    peer_review_count: number;
    form: FormData;
    active: boolean;
    createdAt: string;
    updatedAt: string;
}

export interface SubmissionI {
    id: number;
    programmingAssignmentId: number;
    userId: number;
    userUuid: string;
    code: Record<string, unknown>;
    processed: boolean;
    testErrors: Record<string, unknown>[];
    testResults: {
        passed: boolean;
        testName: string;
        testErrors: string;
    }[];
    createdAt: string;
    updatedAt: string;
    submission_review_count?: number; // special field received only from one endpoint. Not strictly necessary.
}

export interface ProgrammingAssignmentI {
    id: number;
    assignmentId: number;
    order: number;
    maxPoints: number;
    title: string;
    handout: string;
    code: {
        starter: Record<string, string>;
    };
    type?: string;
    gradingType?: string;
    assignment?: {
        maxPoints: number;
        assignmentOrder: number;
    };
    createdAt?: string;
    updatedAt?: string;
}

export type SubmissionReviewStatus =
    | 'hasAdminReview'
    | 'missingAdminReview'
    | 'inProgressAdminReview';
