import React, { createContext, useState } from 'react';

interface ProgrammingAssignmentReviewFilterContextI {
    selectedUsers: string[];
    selectedProgrammingAssignments: string[];
    filtersExpanded: boolean;
    userSelected: boolean;
    programmingAssignmentSelected: boolean;
    handleUserSelect: (
        event: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleProgrammingAssignmentSelect: (
        event: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleFilterAccordionToggle: (
        _event: unknown,
        isExpanded: boolean | ((prevState: boolean) => boolean),
    ) => void;
    toggleUserSelect: (userId: string) => () => void;
    toggleProgrammingAssignmentSelect: (paId: string) => () => void;
    clearUserSelect: () => void;
    clearProgrammingAssignmentSelect: () => void;
}

export const ProgrammingAssignmentReviewFilterContext = createContext<
    ProgrammingAssignmentReviewFilterContextI
>({
    selectedUsers: [],
    selectedProgrammingAssignments: [],
    filtersExpanded: false,
    userSelected: false,
    programmingAssignmentSelected: false,
    handleFilterAccordionToggle: () => undefined,
    handleUserSelect: () => undefined,
    handleProgrammingAssignmentSelect: () => undefined,
    toggleUserSelect: () => () => undefined,
    toggleProgrammingAssignmentSelect: () => () => undefined,
    clearUserSelect: () => undefined,
    clearProgrammingAssignmentSelect: () => undefined,
});

export const ProgrammingAssignmentReviewAdminPageFilterProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
    const [selectedProgrammingAssignments, setSelectedProgrammingAssignments] = useState<string[]>(
        [],
    );

    // UI STATE
    const [filtersExpanded, setFiltersExpanded] = useState(false);

    const userSelected = selectedUsers.length > 0;
    const programmingAssignmentSelected = selectedProgrammingAssignments.length > 0;

    // ---------------- HANDLERS ---------------------------
    // ! The handlers should be moved to a specific component using it
    // * This would mean passing fewer functions in the provider...

    const handleUserSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedUsers(event.target.value as string[]);
    };

    const handleProgrammingAssignmentSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedProgrammingAssignments(event.target.value as string[]);
    };

    const toggleUserSelect = (userId: string) => () => {
        setSelectedUsers((prev) => {
            if (prev.includes(userId)) {
                return prev.filter((id) => id !== userId);
            } else {
                return [...prev, userId];
            }
        });
    };

    const clearUserSelect = () => setSelectedUsers([]);

    const toggleProgrammingAssignmentSelect = (paId: string) => () => {
        setSelectedProgrammingAssignments((prev) => {
            if (prev.includes(paId)) {
                return prev.filter((id) => id !== paId);
            } else {
                return [...prev, paId];
            }
        });
    };

    const clearProgrammingAssignmentSelect = () => setSelectedProgrammingAssignments([]);

    const handleFilterAccordionToggle = (
        _event: unknown,
        isExpanded: boolean | ((prevState: boolean) => boolean),
    ) => {
        setFiltersExpanded(isExpanded);
    };

    const contextValue: ProgrammingAssignmentReviewFilterContextI = {
        selectedUsers,
        selectedProgrammingAssignments,
        filtersExpanded,
        userSelected,
        programmingAssignmentSelected,
        handleUserSelect,
        handleProgrammingAssignmentSelect,
        handleFilterAccordionToggle,
        toggleUserSelect,
        toggleProgrammingAssignmentSelect,
        clearUserSelect,
        clearProgrammingAssignmentSelect,
    };

    return (
        <ProgrammingAssignmentReviewFilterContext.Provider value={contextValue}>
            {children}
        </ProgrammingAssignmentReviewFilterContext.Provider>
    );
};

export default ProgrammingAssignmentReviewAdminPageFilterProvider;
