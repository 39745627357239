import { Box, Divider, List, Tab, Tabs } from '@mui/material';
import React, { useCallback, useContext, useMemo } from 'react';
import { ProgrammingAssignmentReviewDataContext } from '../review-admin-page-data-provider';
import { ProgrammingAssignmentReviewFilterContext } from '../review-admin-page-filter-provider';
import sxPageStyles from '../styles/page-styles';
import { SubmissionI } from '../../types';
import ProgrammingAssignmentSubmissionListItem from './programming-assignment-submission-list-item';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            sx={{ overflowY: 'scroll', width: '100%', height: '100%' }}
            role="tabpanel"
            hidden={value !== index}
            id={`project-submission-tabpanel-${index}`}
            aria-labelledby={`project-submissions-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </Box>
    );
}

function a11yProps(index: number) {
    return {
        id: `project-submissions-tab-${index}`,
        'aria-controls': `project-submission-tabpanel-${index}`,
    };
}

interface Props {
    mode: 'inspect' | 'review';
}

const ProgrammingAssignmentSubmissionListContainer = (props: Props) => {
    const DATA_CONTEXT = useContext(ProgrammingAssignmentReviewDataContext);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const getStatus = (submission: SubmissionI) => {
        const reviewSubmissions = DATA_CONTEXT.programmingAssignmentReviewSubmissions;
        const adminReviewsForSubmission = reviewSubmissions.filter(
            (pars) => pars.programmingAssignmentSubmissionId === submission.id,
        );

        const completedAdminReview = adminReviewsForSubmission.find((pars) => !!pars.review);

        if (completedAdminReview) return 'hasAdminReview';

        const inProgressAdminReview = adminReviewsForSubmission.find((pars) => !pars.review);

        // console.log('BUU', submission, adminReviewsForSubmission, reviewSubmissions);
        return inProgressAdminReview ? 'inProgressAdminReview' : 'missingAdminReview';
    };

    const filterSubmissionsWithoutAdminReview = (submission: SubmissionI) => {
        const submissionHasAdminReview = 'hasAdminReview' !== getStatus(submission);
        return submissionHasAdminReview;
    };

    const createComparisonByDate = (direction: 'ASC' | 'DESC') => {
        switch (direction) {
            case 'ASC':
                return (a: SubmissionI, b: SubmissionI) =>
                    new Date(a.createdAt) <= new Date(b.createdAt) ? -1 : 1;
            case 'DESC':
                return (a: SubmissionI, b: SubmissionI) =>
                    new Date(a.createdAt) >= new Date(b.createdAt) ? -1 : 1;
        }
    };

    const comparisonByDateDescending = useMemo(() => createComparisonByDate('DESC'), []);
    const comparisonByDateAscending = useMemo(() => createComparisonByDate('ASC'), []);

    const selectedSubmissionResult = DATA_CONTEXT.programmingAssignmentSubmissions?.filter(
        (submission) =>
            submission.id ===
            DATA_CONTEXT.reviewSubmissionForCurrentUser?.programmingAssignmentSubmissionId,
    );

    const selectedSubmissionForReview =
        Array.isArray(selectedSubmissionResult) && selectedSubmissionResult.length === 1
            ? selectedSubmissionResult[0]
            : undefined;

    const selectedSubmissionForInspection = DATA_CONTEXT.selectedProgrammingAssignmentSubmission;

    const onSubmissionSelect = (submission: SubmissionI) => {
        if (props.mode === 'review') return;

        if (submission?.id === selectedSubmissionForInspection?.id) {
            DATA_CONTEXT.setSelectedProgrammingAssignmentSubmission(undefined);
            return;
        }
        DATA_CONTEXT.setSelectedProgrammingAssignmentSubmission(submission);
    };

    return (
        <Box
            sx={{
                py: 2,
                height: '100%',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}
        >
            {props.mode === 'review' && selectedSubmissionForReview && (
                <List sx={{ px: 2 }} disablePadding>
                    <ProgrammingAssignmentSubmissionListItem
                        key={`programming-assignment-submission-${selectedSubmissionForReview.id}`}
                        programmingAssignmentSubmission={selectedSubmissionForReview}
                        // selected={true}
                        {...props}
                    />
                </List>
            )}
            {props.mode === 'inspect' && selectedSubmissionForReview && (
                <List sx={{ px: 2 }} disablePadding>
                    <ProgrammingAssignmentSubmissionListItem
                        key={`programming-assignment-submission-${selectedSubmissionForReview.id}`}
                        programmingAssignmentSubmission={selectedSubmissionForReview}
                        selected={
                            selectedSubmissionForInspection?.id === selectedSubmissionForReview.id
                        }
                        onClick={() => onSubmissionSelect(selectedSubmissionForReview)}
                        {...props}
                    />
                </List>
            )}
            {selectedSubmissionForReview && (
                <Divider sx={{ height: '2px', width: '100%', py: 2 }} />
            )}

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: '1',
                    overflow: 'hidden',
                    pl: 2,
                }}
            >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="project submission category tabs"
                    >
                        <Tab label="PENDING" {...a11yProps(0)} />
                        <Tab label="ALL" {...a11yProps(1)} />
                    </Tabs>
                </Box>
                <Box flexGrow="1" overflow="hidden">
                    <TabPanel value={value} index={0}>
                        <List disablePadding>
                            {DATA_CONTEXT.programmingAssignmentSubmissions
                                ?.filter(filterSubmissionsWithoutAdminReview)
                                .filter(
                                    (submission) =>
                                    props.mode === 'inspect' || submission.id !==
                                        DATA_CONTEXT.reviewSubmissionForCurrentUser
                                            ?.programmingAssignmentSubmissionId,
                                )
                                .slice()
                                .sort(comparisonByDateAscending)
                                .map((item) => (
                                    <ProgrammingAssignmentSubmissionListItem
                                        key={`programming-assignment-submission-${item.id}`}
                                        programmingAssignmentSubmission={item}
                                        selected={selectedSubmissionForInspection?.id === item.id}
                                        onClick={() => onSubmissionSelect(item)}
                                        {...props}
                                    />
                                ))}
                        </List>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <List disablePadding>
                            {DATA_CONTEXT.programmingAssignmentSubmissions
                                .filter(
                                    (submission) =>
                                        submission.id !==
                                        DATA_CONTEXT.reviewSubmissionForCurrentUser
                                            ?.programmingAssignmentSubmissionId,
                                )
                                .slice()
                                .sort(comparisonByDateDescending)
                                .map((item) => (
                                    <ProgrammingAssignmentSubmissionListItem
                                        key={`programming-assignment-submission-${item.id}`}
                                        programmingAssignmentSubmission={item}
                                        selected={selectedSubmissionForInspection?.id === item.id}
                                        onClick={() => onSubmissionSelect(item)}
                                        {...props}
                                    />
                                ))}
                        </List>
                    </TabPanel>
                </Box>
            </Box>
        </Box>
    );
};

export default ProgrammingAssignmentSubmissionListContainer;
